<template>
    <div class="scan-page">
        <div class="alert alert-centered rounded-0 mb-0" :class="repairKit.priority ? 'alert-danger' : 'alert-info'"
             v-if="repairKit && repairKit.in_house">
            <animated-info></animated-info>
            In-house<span v-if="repairKit.priority"> priority</span> repair
        </div>
        <div class="alert alert-centered rounded-0 mb-0 alert-info"
             v-if="repairKit && repairKit.is_apple_gen">
            <animated-info></animated-info>
            Apple Genuine Repair
        </div>

        <!-- Barcode picker -->
        <scanner
            @barcodeHasBeenScanned="addDetailToRepairKit"
            ref="barcodePicker"
        ></scanner>

        <template v-if="repairKit">
            <!-- Details -->
            <div class="scan-details">
                <div class="container">
                    <h4 class="d-flex align-items-center mb-2">Repair kit {{ repairKitId }}
                        <button class="btn btn-info btn-sm ml-auto" @click="printRepairKitLabel(repairKitId)">
                            <template v-if="repairKit.label_printed">Reprint</template>
                            <template v-if="!repairKit.label_printed">Print</template>
                            kit label
                        </button>
                    </h4>
                    <h5 class="text-subtitle mb-0">{{ detailsPicked.length }} of {{ repairKitDetails.length }} picked</h5>
                </div>
            </div>

            <!-- Item list -->
            <div class="container scan-content">
                <b-tabs>

                    <!-- To be picked -->
                    <b-tab title="To be picked" active>
                        <div class="card">
                            <ul class="checklist my-0" v-if="detailsToBePicked.length > 0">
                                <li
                                    v-for="detail in kitItems"
                                    :key="detail.id"
                                    :class="{'complete': detailComplete(detail)}"
                                    class="checklist-item no-hover"
                                >
                                    <small v-if="repairKit.warehouse_id === 3">
                                        <b>{{ detail.item_location }}</b>
                                    </small>
                                    {{ detailTitle(detail) }}
                                </li>
                            </ul>
                            <empty-state
                                v-if="detailsToBePicked.length < 1"
                                emptyIcon
                                icon="stock-pick"
                                inline
                                small
                                title=""
                                subtitle="No items to be picked">
                            </empty-state>
                        </div>
                    </b-tab>

                    <!-- Picked -->
                    <b-tab title="Picked">
                        <div class="card">
                            <ul class="checklist my-0" v-if="detailsPicked.length > 0">
                                <li
                                    v-for="detail in detailsPicked"
                                    :key="detail.id"
                                    :class="{'complete': detailComplete(detail)}"
                                    class="checklist-item no-hover"
                                >
                                    <small v-if="repairKit.warehouse_id === 3">
                                        <b>{{ detail.item_location }}</b>
                                    </small>
                                    {{ detailTitle(detail) }}
                                </li>
                            </ul>
                            <empty-state
                                v-if="detailsPicked.length < 1"
                                emptyIcon
                                icon="stock-pick"
                                inline
                                small
                                title=""
                                subtitle="No items have been picked">
                            </empty-state>
                        </div>
                    </b-tab>

                    <!-- All items -->
                    <b-tab title="All items">
                        <div class="card">
                            <ul class="checklist my-0" v-if="repairKitDetails.length > 0">
                                <li
                                    v-for="detail in repairKitDetails"
                                    :key="detail.id"
                                    :class="{'complete': detailComplete(detail)}"
                                    class="checklist-item no-hover"
                                >
                                    <small v-if="repairKit.warehouse_id === 3">
                                        <b>{{ detail.item_location }}</b>
                                    </small>
                                    {{ detailTitle(detail) }}
                                </li>
                            </ul>
                            <empty-state
                                v-if="repairKitDetails.length < 1"
                                emptyIcon
                                icon="stock-pick"
                                inline
                                small
                                title=""
                                subtitle="No items in this repair kit">
                            </empty-state>
                        </div>
                    </b-tab>
                </b-tabs>
            </div>
        </template>
    </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import _ from 'lodash';

export default {
    props: ['repairKitId'],

    data() {
        return {
            repeatScanningCheck: 0
        }
    },

    mounted() {
        this.repeatScanningCheck = 0;

        this.loadRepairKit({
            repairKitId: this.repairKitId
        });

        this.loadRepairKitDetails({
            repairKitId: this.repairKitId
        }).then(() => {
            this.isPickingComplete();
        });
    },

    computed: {
        ...mapGetters([
            'appRegion',
            'repairKit',
            'repairKitDetails',
            'detailsPicked',
            'detailsToBePicked'
        ]),
        kitItems() {
            return _.orderBy(this.detailsToBePicked, 'item_location', 'asc');
        }
    },

    methods: {
        ...mapActions([
            'displayToast',
            'loadRepairKit',
            'loadRepairKitDetails',
            'printRepairKitLabel',
            'repairKitDetailBarcodeScanned',
            'setRepairKitAsPickedDirectly',
            'logToApi'
        ]),

        /** Add scanned detail */
        addDetailToRepairKit(barcodeScanned) {
            this.repairKitDetailBarcodeScanned({barcodeScanned: barcodeScanned, repairKitId: this.repairKitId})
                .then(() => {
                    this.displayToast({text: "Scanned!", type: 'success', duration: 1000});

                    if (barcodeScanned.type === 'serialNumber') {
                        this.logToApi({
                            action: 'repair_kit_picking',
                            event: 'detail_scanned',
                            repairKitId: this.repairKitId,
                            assetId: null,
                            stockSerialNumberId: barcodeScanned.id
                        });

                    } else if (barcodeScanned.type === 'asset') {
                        this.logToApi({
                            action: 'repair_kit_picking',
                            event: 'detail_scanned',
                            repairKitId: this.repairKitId,
                            assetId: barcodeScanned.id
                        });
                    }

                    this.repeatScanningCheck = 0;
                    this.isPickingComplete();

                }).catch(message => {
                this.repeatScanningCheck++;
                if (message !== 'Serial number already scanned' || this.repeatScanningCheck > 1) {
                    this.displayToast({text: message, type: 'error', duration: 20000});
                }
            });
        },

        /** Check if picking is complete */
        isPickingComplete() {
            this.loadRepairKitDetails({
                repairKitId: this.repairKitId
            }).then(() => {
                if (this.detailsPicked.length === this.repairKitDetails.length) {
                    this.setRepairKitAsPickedDirectly(this.repairKitId).then(() => {
                        this.logToApi({action: 'repair_kit_picking', event: 'finish', repairKitId: this.repairKitId});
                        this.$router.push({name: 'repairKitPicking', params: {kitPicked: true}});
                    });
                }
            });
        },

        /** Return true if the detail has been picked */
        detailComplete(detail) {
            return (detail.stock_serial_number_id || detail.picked);
        },

        detailTitle(detail) {
            if (detail.type === 'jobstockitem' || detail.type === 'stockitem') {
                return detail.stock_item_title;
            }
            if (detail.type === 'asset') {
                return `Asset: ${detail.asset_day_number} (${detail.asset_reference})`;
            }
            return 'ERROR'
        }
    }
}
</script>

<style scoped>

</style>
